<template>
  <div class="page-join-container">
    <div class="join-container__wrapper">
      <div class="join__title">join us</div>
      <div class="join__job-list-container" v-if="lists">
        <div
          class="job-list__item scrollObj"
          v-for="(job, index) in lists"
          :key="index"
          :class="{
            'in-view': index === lists.length - 1
          }"
          data-effect="bg"
          data-color="#fff"
        >
          <div class="item__children item__queue">
            <p>no.</p>
            <p>{{ parseQueue(index + 1) }}/</p>
          </div>
          <div class="item__children item__job-name">
            <div class="wp">
              <div class="txtsp">
                {{ job.name.en }} | <span>{{ job.name.cn }}</span>
              </div>
              <div class="content">
                <p class="txt">
                  {{ job.name.en }} | <span>{{ job.name.cn }}</span>
                </p>
                <p class="txt">
                  {{ job.name.en }} | <span>{{ job.name.cn }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="item__children item__job-content">
            <div class="__cn" v-html="job.content.cn"></div>
            <div class="__en" v-html="job.content.en"></div>
          </div>
        </div>
      </div>
      <div class="join__apply-container">
        <div class="apply__title scrollObj">
          <p>To Apply</p>
          <p>应聘渠道</p>
        </div>
        <div class="apply__item_wp">
          <div class="apply__item scrollObj">
            <p>E-mail</p>
            <p>官方邮箱：{{ jobMail ? jobMail.value_text : "" }}</p>
          </div>
          <div class="apply__item scrollObj">
            <p>Tel</p>
            <p>官方电话：{{ jobTel ? jobTel.value_text : "" }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "page-join",
  data() {
    return {
      lists: null
    };
  },
  computed: {
    ...mapGetters(["jobMail", "jobTel"])
  },
  watch: {
    $route: {
      handler: "init",
      immediate: true
    }
  },
  mounted() {},
  methods: {
    ...mapMutations(["PAGE_LOADING", "PAGE_LOADED"]),
    init() {
      this.PAGE_LOADING();
      this.getList()
        .then(res => {
          this.lists = res.data.lists;
          if (this.lists) {
            this.$nextTick(() => {
              //文字宽度自适应
              const wp = Array.from(document.querySelectorAll(".wp"));
              wp.forEach(item => {
                const txt = item.querySelector(".txtsp");
                item.style.width = `${txt.getBoundingClientRect().width}px`;
              });
              document.body.classList.add("xxx");
            });
          }
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.PAGE_LOADED();
        });
    },
    parseQueue(val) {
      return val < 10 ? `0${val}` : val;
    },
    getList() {
      return this.$request.get("/api/job/list");
    }
  }
};
</script>

<style></style>
